<template>
  <component
    :is="to || prismicTo || computedTo || href || prismicHref || computedHref ? (isInternal || computedIsInternal ? 'nuxt-link' : 'a') : 'div'"
    :to="computedTo ? computedTo : (to ? to : prismicTo)"
    :target="!isInternal && !computedIsInternal ? '_blank' : null"
    class="image-wrapper"
    :class="{ 'is-loaded' : isLoaded }"
  >
    <div class="lazy-image-placeholder" :style="`padding-bottom: ${paddingBottom}%;`">
      <img
        ref="image"
        :data-src="!disableLazy ? computedSrc : ''"
        :data-srcset="!disableLazy && computedRetinaSrc ? `${computedSrc} 1x, ${computedRetinaSrc} 2x` : ''"
        :src="(disableLazy || isReloaded) ? computedSrc : ''"
        :srcset="(disableLazy || isReloaded) && computedRetinaSrc ? `${computedSrc} 1x, ${computedRetinaSrc} 2x` : ''"
        :alt="alt"
        class="lazy-image"
      />
    </div>
  </component>
</template>

<script>
  import lozad from 'lozad';

  import prismicLinkMixin from '@/mixins/prismicLinkMixin';

  export default {
    name: 'LazyImage',

    mixins: [ prismicLinkMixin ],

    components: {
      SvgIcon: () => import('@/components/global/SvgIcon')
    },

    props: {
      computedTo: {
        type: String,
        default: null,
      },
      computedHref: {
        type: String,
        default: null,
      },
      computedIsInternal: {
        type: Boolean,
        default: null,
      },
      src: {
        type: String,
        default: null,
      },
      retinaSrc: {
        type: String,
        default: null,
      },
      mobileSrc: {
        type: String,
        default: null,
      },
      mobileRetinaSrc: {
        type: String,
        default: null,
      },
      width: {
        type: [String, Number],
        default: 500,
      },
      height: {
        type: [String, Number],
        default: 500,
      },
      alt: {
        type: String,
        default: null
      },
      disableLazy: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isLoaded: false,
        isReloaded: false
      }
    },

    computed: {
      paddingBottom() {
        if (!this.width || !this.height) return 100;

        return (this.height / this.width) * 100;
      },
      isMobile() {
        if (process.client) {
          const w =
            window.innerWith ||
            document.documentElement.clientWidth ||
            document.body.clientWidth

          return w <= 600;
        }
      },
      computedSrc() {
        if (this.mobileSrc) {
          return this.isMobile ? this.mobileSrc : this.src;
        } else {
          return this.src;
        }
      },
      computedRetinaSrc() {
        if (this.mobileRetinaSrc) {
          return this.isMobile ? this.mobileRetinaSrc : this.retinaSrc;
        } else {
          return this.retinaSrc;
        }
      }
    },

    watch: {
      src() {
        this.isReloaded = true;
      }
    },

    mounted() {
      this.$nextTick(() => {
        var $img = this.$refs.image;

        if ($img) {
          // As soon as the <img> element triggers the `load` event, the isLoaded state is set to `true`
          const setDoneLoading = () => {
            this.isLoaded = true;
          };

          $img.addEventListener('load', setDoneLoading);

          // Remove the event listener as soon as the component is destroyed to prevent potential memory leaks
          this.$once('hook:destroyed', () => {
            $img.removeEventListener('load', setDoneLoading);
          });

          // Initialize Lozad.js on the root element of our component
          const observer = lozad($img);
          observer.observe();
        }

        if(!this.isInternal && !this.computedIsInternal && (this.computedHref || this.href || this.prismicHref)) {
          this.$el.setAttribute('href', this.computedHref ? this.computedHref : (this.href ? this.href : this.prismicHref));
        }
      });
    }
  }
</script>
